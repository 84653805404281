<template>
  <comunicacion-form
    btnSubmit="Crear Comunicación"
    :comunicacion.sync="data"
    @processForm="agregar"
  />
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

// // FORMATOS
// import { formatos } from '@/mixins/formatos'

import ComunicacionForm from './ComunicacionForm.vue'

export default {
  components: {
    // COMPONENTES
    ComunicacionForm,
  },
  directives: {
    Ripple,
  },
  // mixins: [formatos],
  data() {
    return {
      data: {
        tipo: null,
        titulo: '',
        detalle: '',
        fecha_envio: '',
        hora_envio: '',
        estado: null,
        cursos: [],
        alumnos: [],
      },
    }
  },
  methods: {
    ...mapActions({
      createComunicacion: 'comunicaciones/addComunicacion',
    }),
    agregar(comunicacion) {
      this.createComunicacion(comunicacion).then(() => {
        const statusComunicaciones = store.state.comunicaciones.status
        if (statusComunicaciones === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Comunicación guardada 👍',
              icon: 'CheckIcon',
              text: 'La comunicación fue ingresada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })

          this.$router.replace({
            name: 'comunicaciones-apoderados',
          })
        } else {
          this.$swal({
            title: 'Atención!',
            text: store.state.comunicaciones.message,
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
